import { Locale, format as fnsFormat, setDefaultOptions } from 'date-fns';
import { es } from 'date-fns/locale';

export function dateFormat(
  date: Date | string,
  format: string,
  options?: {
    locale?: Locale;
    weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
    firstWeekContainsDate?: number;
    useAdditionalWeekYearTokens?: boolean;
    useAdditionalDayOfYearTokens?: boolean;
  },
) {
  setDefaultOptions({
    locale: options?.locale || es,
  });
  return fnsFormat(new Date(date), format);
}
