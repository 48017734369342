import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { dateFormat } from './date-format';
// import { es, enUS } from "date-fns/locale";

// const locales = { es, enUS };

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const nativeEnumToArray = <T extends object>(obj: T): Array<keyof T> =>
  Object.keys(obj) as Array<keyof T>;

export const feellingLevelEmoji = ({ type }: { type: string }) => {
  switch (type) {
    case 'bad':
      return '🙁';
    case 'regular':
      return '😐';
    case 'good':
      return '😀';
    default:
      return '😔';
  }
};

export const localeDateTime = (
  date: Date | string,
  format?: string,
  options?:
    | {
        weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | undefined;
        firstWeekContainsDate?: number | undefined;
        useAdditionalWeekYearTokens?: boolean | undefined;
        useAdditionalDayOfYearTokens?: boolean | undefined;
      }
    | undefined,
) => {
  if (!date) {
    return null;
  }

  const parsedDate = typeof date === 'string' ? new Date(date) : date;

  if (!!format || !!options) {
    return dateFormat(parsedDate.toISOString(), format ? format : '', {
      ...options,
    });
  }

  return `${dateFormat(parsedDate.toISOString(), 'PP')}`;
};
